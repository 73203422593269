export enum SWR_KEYS {
  LIST_USERS = 'list-users',
  LIST_USER_ALIASES = 'list-user-aliases',
  LIST_LOGIN_USERS = 'list-login-users',
  LIST_CANNED_REPLIES = 'list-canned-replies',
  LIST_CUSTOM_FIELDS = 'list-custom-fields',
  GET_PROFILE_IFRAME_SRC = 'get-profile-iframe-src',
  GET_PROFILE_IFRAME_DATA = 'get-profile-iframe',
  LIST_INBOX_VIEWS = 'list-inbox-views',
  GET_INBOX_VIEW = 'get-inbox-view',
  GET_NUM_CONVERSATIONS_GROUPED_BY_STATUS = 'get-num-conversations-grouped-by-status',
  GET_IP_ALLOWLIST = 'get-ip-allowlist',
  GET_CHATBOT_CHANNELS = 'get-chatbot-channels',
  GET_CHATBOT_WEBCHAT_CHANNEL = 'get-chatbot-webchat-channel',
  GET_CHATBOT_MESSENGER_CHANNEL = 'get-chatbot-messenger-channel',
  LIST_BAN_REASONS = 'list-ban-reasons',
  LIST_BAN_RULES = 'list-ban-rules',
  LIST_TAGS = 'list-tags',
  LIST_TAG_GROUPS = 'list-tag-groups',
  GET_WORKSPACE_DETAILS = 'get-workspace-details',
  LIST_ALL_ROLES = 'list-all-roles',
  GET_INHERITANCE_MAP = 'get-inheritance-map',
  GET_CHAT_ASSIGNMENT_SETTINGS = 'get-chat-assignment-settings',
  GET_CUSTOMER_CONVERSATION_HISTORY = 'get-customer-conversation-history',
  LIST_SUBCOMPANIES = 'list-subcompanies',
  LIST_TEAM_ROLES = 'list-team-roles',
  LIST_TEAMS = 'list-teams',
  LIST_INVITATIONS = 'list-invitations',
  LIST_ACCOUNT_ROLES = 'list-account-roles',
  GET_ACTIVE_PRODUCTS = 'get-active-products',
  GET_PERIOD_BALANCE = 'get-period-balance',
  GET_WORKSPACE_BILLING_EXISTS = 'get-workspace-billing-exists',
  GET_FREE_PERIOD_MESSAGE_COUNT = 'get-free-period-message-count',
  GET_PROGPT_TOKEN_COUNT = 'get-progpt-token-count',
  LIST_CHATBOTS = 'list-chatbots',
  GET_CHATBOT = 'get-chatbot',
  GET_CUSTOMER = 'get-customer',
  GET_CONVERSATION_DETAILS = 'get-conversation-details',
  LIST_EMAIL_DOMAINS = 'list-email-domains',
  LIST_EMAIL_TEMPLATES = 'list-email-templates',
  GET_EMAIL_DOMAIN = 'get-email-domain',
  GET_EMAIL_TEMPLATE = 'get-email-template',
  LIST_EMAIL_SENDERS = 'list-email-senders',
  LIST_NESTED_EMAIL_SENDERS = 'list-nested-email-senders',
  GET_TAGS_ENFORCEMENT_SETTINGS = 'get-tags-enforcement-settings',
  GET_WORKSPACE_WHITE_LABEL_CONFIGURATION = 'get-workspace-white-label-configuration',
  GET_STATE = 'get-state',
  LIST_INTENTS = 'list-intents',
  READ_EVENT = 'read-event',
  JUMP_ACTION_QUERY_TARGET = 'jump-action-query-target',
}
