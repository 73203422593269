import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const mixpanelProjectToken = import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN;
mixpanel.init(mixpanelProjectToken, {
  debug: import.meta.env.MODE === 'development',
  api_host: import.meta.env.VITE_MIXPANEL_API_HOST,
  ignore_dnt: false,
  cross_subdomain_cookie: false,
});

export function useMixpanelTracking() {
  const location = useLocation();

  useEffect(() => {
    mixpanel.track_pageview();
  }, [location]);
}
