export enum StripeProduct {
  PROGPT_MAX = 'progpt_max',
  PROGPT_CORE = 'progpt_core',
  PRODATA = 'prodata',
  PROANALYTICS = 'proanalytics',
  PROTO_AICX_BASE = 'proto_aicx_base',
  PROTO_AICX_PROGPT = 'proto_aicx_progpt',
}

export enum StripeBillingPortalFlowType {
  PAYMENT_METHOD_UPDATE = 'payment_method_update',
}
