import { useFetch } from '@/lib/api';
import { StripeProduct } from '@/lib/enums/billing';
import { SWR_KEYS } from '@/lib/enums/swr';
import { createContext, useContext, useMemo } from 'react';
import { AuthContext } from './AuthContext';

type IBilling = ReturnType<typeof BillingProvider>['value'];

export const BillingContext = createContext<IBilling>({} as IBilling);

const FREE_ACCOUNT_PRO_GPT_TOKENS = 10;
const FREE_ACCOUNT_MESSAGES = 1000;

export default function BillingProvider({ children }: { children: React.ReactNode }) {
  const { id } = useContext(AuthContext);
  const { data: activeProducts } = useFetch<StripeProduct[]>(
    SWR_KEYS.GET_ACTIVE_PRODUCTS,
    id ? '/billing/get-active-products' : null
  );

  const activeAddonsMap = useMemo<Record<StripeProduct, boolean | null> | null>(() => {
    if (activeProducts) {
      const _activeAddonsMap = {} as Record<StripeProduct, boolean | null>;
      for (const product of Object.values(StripeProduct)) {
        _activeAddonsMap[product] = activeProducts.includes(product);
      }
      return _activeAddonsMap;
    } else {
      return null;
    }
  }, [activeProducts]);

  const isWorkspaceFreeTier = useMemo(() => {
    if (activeAddonsMap) {
      return (
        activeAddonsMap[StripeProduct.PROTO_AICX_BASE] === false &&
        activeAddonsMap[StripeProduct.PROTO_AICX_PROGPT] === false
      );
    } else {
      return null;
    }
  }, [activeAddonsMap]);

  // proGPT Free Tier
  const { data: progptTokenCount } = useFetch<{ count: number }>(
    SWR_KEYS.GET_PROGPT_TOKEN_COUNT,
    isWorkspaceFreeTier && id ? '/analytics/get-progpt-token-count' : null
  );
  const canFreeAccountUseProGPTToken = useMemo(() => {
    if (progptTokenCount) {
      return progptTokenCount.count < FREE_ACCOUNT_PRO_GPT_TOKENS;
    } else {
      return null;
    }
  }, [progptTokenCount]);

  // Proto AICX Base Message Free Tier
  const { data: freePeriodMessageCount } = useFetch<{ count: number; avg_days_left: number }>(
    SWR_KEYS.GET_FREE_PERIOD_MESSAGE_COUNT,
    isWorkspaceFreeTier && id ? '/analytics/get-free-period-message-count' : null
  );
  const canFreeAccountUseMessage = useMemo(() => {
    if (freePeriodMessageCount) {
      return freePeriodMessageCount.count < FREE_ACCOUNT_MESSAGES;
    } else {
      return null;
    }
  }, [freePeriodMessageCount]);

  const value = {
    activeAddonsMap,
    isWorkspaceFreeTier,
    freePeriodMessageCount,
    progptTokenCount,
    canFreeAccountUseProGPTToken,
    canFreeAccountUseMessage,
  } as const;

  return {
    ...(<BillingContext.Provider value={value}>{children}</BillingContext.Provider>),
    value,
  };
}
