import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { Link, useRouteError } from 'react-router-dom';
import { buttonVariants } from '../ui/button';

export function SentryRouteErrorFallback() {
  const routeError = useRouteError();
  const [eventId, setEventId] = useState<string>('');

  useEffect(() => {
    setEventId(captureException(routeError, { level: 'fatal' }));
  }, [routeError]);

  return (
    <div className="min-h-vh relative flex w-full items-center justify-center px-2">
      <div className="z-10 w-full max-w-[410px] rounded-2xl border-border bg-background px-12 py-10 sm:border">
        <h1 className="text-2xl font-semibold tracking-tight">Something went wrong</h1>
        <div className="mt-6 text-sm">We're sorry, an error occurred while rendering this page.</div>
        <div className="mt-6">
          <Link className={buttonVariants({ variant: 'outline' })} to="/">
            Go back to Proto
          </Link>
        </div>
        {eventId && (
          <div className="mt-6 text-2xs">
            Event <code>{eventId}</code>
          </div>
        )}
      </div>
    </div>
  );
}
